import { useEffect, useState } from 'react';
import tinyColor from 'tinycolor2';

const toRGB = color => {
    const { r, g, b } = tinyColor(color).toRgb();
    return `${r}, ${g}, ${b}`;
};

const lightness = color => parseInt(tinyColor(color).toHsl().l * 100);

const amount = 5;
const dark = '#1E2A46';
const light = '#ffffff';

const useColor = (color, over = 'hero') => {
    const [colors, setColors] = useState({});

    useEffect(() => {
        if (color) {
          const setColor = lightness(color) > 60 ? dark : light;
          setColors({
            [`--${over}`]: color,
            [`--${over}-hover`]: lightness(color) > 50 ? tinyColor(color).darken(amount) : tinyColor(color).lighten(amount),
            [`--${over}-sc`]: setColor,
            [`--${over}-rgb`]: toRGB(color),
            [`--${over}-sc-rgb`]: toRGB(setColor),
          });
        }
    }, [color, over]);
    return colors;
};

export default useColor;
