import React, { useContext } from 'react';

import { ConfigContext } from '../../App';

import './index.scss';

const Footer = () => {
  const config = useContext(ConfigContext);

  return (
    <footer>
      <ul className="guide">
        <li>
          <div className="company-name">
            {`${config.name} ${new Date().getFullYear()}`}
          </div>
        </li>
        <li>
          {config.isNewsEnabled && (
            <>
              <a target="_blank" rel="noopener noreferrer" href="https://www.betconstruct.com/terms-and-conditions">
                Terms & Conditions
              </a>
              <a target="_blank" rel="noopener noreferrer" href="https://www.betconstruct.com/privacy-policy">
                Privacy Policy
              </a>
              <a target="_blank" rel="noopener noreferrer" href="https://www.betconstruct.com/cookie-policy">
                Cookie Policy
              </a>
            </>
          )}
        </li>
      </ul>
    </footer>
  )
};

export default Footer;
