/* Error Handler
  The function below needs to handle all the HTTP/HTTPS requests errors.
*/

import toaster from 'helpers/toaster';
import { dispatchLogoutEvent } from 'helpers/events';

const errorHandler = error => {
  if (error.response.status === 401) {
    dispatchLogoutEvent();
    return;
  }
  toaster(error.response.data.Message || 'Ooops, something went wrong');
};

export default errorHandler;
