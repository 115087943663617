/* API class
  The class described below is an easy interface for
  using REST full API rules. In the whole application is used only
  one isntance of this class somelike a Singletone.
*/

import axios from 'axios';

import configs from 'configs';
import errorHandler from './errorHandler';
const { REACT_APP_ENDPOINT, REACT_APP_ENDPOINT_VERSION } = process.env;

const withCredentials = true;

axios.interceptors.response
  .use(
    response => response.data || true,
    errorHandler,
  );

const endPoint = `${window.apiDomain || REACT_APP_ENDPOINT}/${REACT_APP_ENDPOINT_VERSION}`;

const api = {
  create(url, data, query = '', headers = {}) {
    return axios.post(`${window.apiDomain || REACT_APP_ENDPOINT}/${REACT_APP_ENDPOINT_VERSION}${url}${query}`, data, {
      withCredentials,
      ...headers
    })
  },

  getItem(url, id, query = '') {
    return axios.get(`${window.apiDomain || REACT_APP_ENDPOINT}/${REACT_APP_ENDPOINT_VERSION}${url}/${id}${query}`, { withCredentials, });
  },

  getList(url, query = '', headers = {}) {
    return axios.get(`${window.apiDomain || REACT_APP_ENDPOINT}/${REACT_APP_ENDPOINT_VERSION}${url}${query}`, { withCredentials, headers, });
  },

  updateItem(url, id, data, query = '', headers) {
    return axios.put(`${window.apiDomain || REACT_APP_ENDPOINT}/${REACT_APP_ENDPOINT_VERSION}${url}/${id}${query}`, data, { withCredentials, ...headers });
  },

  modifyItem(url, id, data, query = '') {
    return axios.patch(`${window.apiDomain || REACT_APP_ENDPOINT}/${REACT_APP_ENDPOINT_VERSION}${url}/${id}${query}`, data, { withCredentials, });
  },

  deleteItem(url, id, query = '') {
    return axios.delete(`${window.apiDomain || REACT_APP_ENDPOINT}/${REACT_APP_ENDPOINT_VERSION}${url}/${id}${query}`, { withCredentials, });
  }
};

export default api;
