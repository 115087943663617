import React, { useState } from 'react';
import axios from 'axios';

import { useMount } from 'hooks';

import News from '../news';

import './index.scss';

const limit = 3;
const offset = 0;
const order = 'desc';

const LeftText = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [newsData, setNewsData] = useState([]);

  useMount(() => {
    setIsLoading(true);
    axios.get(`${process.env.REACT_APP_NEWS_URL}?order=${order}&offset=${offset}&limit=${limit}`)
      .then(({ data: { changelog } }) => {
        setIsLoading(false);
        setNewsData(changelog);
      })
      .catch(() => {
        setIsLoading(false);
      });
  });

  return (
    <div className="left-blocks-holder">
      <h1>All-in-one account for all BetConstruct solutions</h1>
      <h2>Start and grow your iGaming business with BetConstruct. Once you sign in, we’ll give you access to all of our products, tools and services through our one-for-all console, SpringBME. Create and manage your gaming websites. Upload and sell your content. Plus so much more.</h2>
      <div className="our-brands-holder">
        <ul>
          <li className="icon-spring-bme-shape" />
          <li className="icon-spring-bme-text" />
        </ul>
        <ul>
          <li className="icon-feed-construct-shape" />
          <li className="icon-feed-construct-text" />
        </ul>
        <ul>
          <li className="icon-spring-shape" />
          <li className="icon-spring-text" />
        </ul>
        <ul>
          <li className="icon-spring-builder-shape" />
          <li className="icon-spring-builder-text" />
        </ul>
      </div>
      <hr />
      <News newsData={newsData} isLoading={isLoading} />
    </div>
  );
}

export default LeftText;
