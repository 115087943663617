import axios from 'axios';
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from 'react-router-dom';
import Notifications from 'react-notify-toast';

import configs from 'configs';
import Layout from 'components/layout';
import Branding from './components/branding';

import makeApplicationRoutes from 'routes';

import api from 'helpers/api';
import { useMount } from 'hooks';
import { dispatchLoginEvent } from 'helpers/events';

import 'assets/fonts/icomoon/style.css';

const { REACT_APP_CUSTOM_NODE_ENV } = process.env;

export const ConfigContext = React.createContext();

const App = props => {
  const [loggedIn, setLoggedIn] = useState(configs.isLoggedIn);
  const [isLoginChecked, setIsLoginChecked] = useState(false);
  const [currentLocation, setCurrentLocation] = useState('');
  const [config, setConfig] = useState(null);

  useMount(() => {
    const loginHandler = _ => {
      setLoggedIn(true);
      configs.isLoggedIn = true;
    };
    const logoutHandler = _ => {
      // TODO:: refactor after react version update
      ReactDOM.unstable_batchedUpdates(() => {
        setLoggedIn(false);
        configs.isLoggedIn = false;
        configs.authenticated = false;
        window.sessionStorage.removeItem('authenticated');
        const analytics = window.analytics;
        analytics && analytics.reset();
      });
    };
    document.addEventListener('userLoggedIn', loginHandler);
    document.addEventListener('userLoggedOut', logoutHandler);

    return () => {
      document.removeEventListener('userLoggedIn', loginHandler);
      document.removeEventListener('userLoggedOut', logoutHandler);
    };
  });

  useEffect(() => {
    setCurrentLocation(window.location.pathname.split('/')[1]);
  }, [props.location.pathname]);

  useMount(() => {
    const host = REACT_APP_CUSTOM_NODE_ENV === 'dev' ? 'dev.accounts-bc.com' : document.location.host;

    axios.get(`https://${host}/config/${host}`).then(configs => {
      window.apiDomain = `https://${configs.apiDomain}`;
      setConfig(configs);
      api.getList('/auth/checkLogin').then(response => {
        response && dispatchLoginEvent();
        setIsLoginChecked(true);
      });
    });
  });

  return config && isLoginChecked ? (
      <>
        <ConfigContext.Provider value={config}>
          <Branding/>
          <Layout
            type={loggedIn ? 'secondary' : 'primary'}
            className={currentLocation}
          >
            {makeApplicationRoutes()}
          </Layout>
        </ConfigContext.Provider>
        <Notifications />
      </>
    )
    : (
      <div className="icon icon-spinner loader-fallback"/>
    );
};

export default withRouter(App);
