import React, { lazy } from 'react';
import { Switch, Router, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import configs from 'configs';
import PrivateRoute from './private';
import SuspenseWrapper from 'elements/stateless/suspense';

const SignIn = lazy(() => import('components/signIn'));
const SignOut = lazy(() => import('components/signOut'));
const Authenticator = lazy(() => import('components/twoFactorAuthentication'));
const Registration = lazy(() => import('components/registration'));
const Profile = lazy(() => import('components/profile'));
const Account = lazy(() => import('components/account'));
const ResetPassword = lazy(() => import('components/resetPassword'));
const ExpiredPassword = lazy(() => import('components/expiredPassword'));

const history = createBrowserHistory();

const notLoggedIn = () => !configs.isLoggedIn;

// TODO make route configs passing through this function

const makeApplicationRoutes = () => (
  <Router history={history}>
    <Switch>
      <PrivateRoute
        exact
        condition={notLoggedIn}
        path="/signin"
        render={props => (
          <SuspenseWrapper>
            <SignIn {...props} />
          </SuspenseWrapper>
        )}
      />
      <PrivateRoute
        exact
        condition={notLoggedIn}
        path="/registration"
        render={props => (
          <SuspenseWrapper>
            <Registration {...props} />
          </SuspenseWrapper>
        )}
      />
      <PrivateRoute
        exact
        condition={notLoggedIn}
        path="/expiredPassword"
        render={props => (
          <SuspenseWrapper>
            <ExpiredPassword {...props} />
          </SuspenseWrapper>
        )}
      />
      <PrivateRoute
        exact
        path="/signOut"
        render={props => (
          <SuspenseWrapper>
            <SignOut {...props} />
          </SuspenseWrapper>
        )}
      />
      <PrivateRoute
        condition={notLoggedIn}
        path="/resetPassword"
        render={props => (
          <SuspenseWrapper>
            <ResetPassword {...props} />
          </SuspenseWrapper>
        )}
      />
      <PrivateRoute
        exact
        condition={() => configs.authenticated}
        path="/authentication"
        render={props => (
          <SuspenseWrapper>
            <Authenticator {...props} />
          </SuspenseWrapper>
        )}
      />
      />
      <PrivateRoute
        exact
        path="/profile"
        render={props => (
          <SuspenseWrapper>
            <Profile {...props} />
          </SuspenseWrapper>
        )}
      />
      <PrivateRoute
        exact
        path="/"
        render={props => (
          <SuspenseWrapper>
            <Account {...props} />
          </SuspenseWrapper>
        )}
      />
      {!configs.isLoggedIn && <Redirect to={configs.authenticated ? '/authentication' : '/signin'} />}
    </Switch>
  </Router>
);

const RouterWrapper = props => (
  <Router history={history}>
    {props.children}
  </Router>
);

export {
  RouterWrapper,
};

export default makeApplicationRoutes;
