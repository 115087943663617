import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { ConfigContext } from '../../App';

import { useColor } from '../../hooks';

const Branding = () => {
  const config = useContext(ConfigContext);

  const colors = useColor(config.primaryColor);

  useEffect(() => {
    if(colors) {
      Object.keys(colors).forEach(color => {
        document.documentElement.style.setProperty(color, colors[color]);
      })
    }
  }, [colors]);

  return (
    <>
      <Helmet>
        <title>{config.name} Accounts</title>
        <link rel="shortcut icon" href={config.favicon} />
      </Helmet>
    </>
  );
};

export default Branding;
