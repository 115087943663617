import React from 'react';

import './index.scss';

const fakeData = Array(3).fill('');

const News = ({ newsData, isLoading }) => {
  return (
    <div className="news-section-holder">
      <ul className="news-head">
        <li>
          <div className="ellipsis-text">News</div>
        </li>
        <li>
          <a
            href={process.env.REACT_APP_NEWS_CONTENT_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            See All
          </a>
        </li>
      </ul>
      <div className="new-items-holder">
        <ul>
          {isLoading ? (
            fakeData.map((item, index) => (
              <li key={index}>
                <div className="news-story">
                  <figure className="skeleton" />
                </div>
              </li>
            ))
          ) : newsData.map(({ title, fileUrl, id }) => (
            <li key={id}>
              <a
                className="news-story"
                href={`${process.env.REACT_APP_NEWS_CONTENT_URL}/item/${id}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <figure
                  style={{
                    backgroundImage: `url(${fileUrl})`,
                  }}
                />
                <div className='n-title-holder'>
                  <p>{title}</p>
                </div>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
};

News.defaultProps = {
  newsData: [],
};

export default News;
