/* The benefot of this component is guarding routes
  that have some reason not be shown to the client when it
  is requested. In the application this is used to protect 
  authorized `profile` route from non authorized requestors.
*/ 

import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import configs from 'configs';

const PrivateRoute = ({ component: Component, condition, render, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const hasAccess = condition ? condition() : configs.isLoggedIn;

      if (hasAccess) {
        return render ? render(props) : <Component {...props} />;
      } else {
        return <Redirect
          to={{
            pathname: configs.isLoggedIn ? '/' : '/signin',
            state: {
              from: props.location
            }
          }}
        />
      }
    }}
  />
);

export default PrivateRoute;
