import React, { Suspense } from 'react';

import Loader from '../loader';

export default function SuspenseWrapper(props) {
  return (
    <Suspense fallback={<Loader />}>
      {props.children}
    </Suspense>
  )
};
