import getQueryVariable from 'helpers/urlQueryParamsParser';

const { REACT_APP_ENDPOINT, REACT_APP_ENDPOINT_VERSION } = process.env;

const returnUrl = getQueryVariable(window.location.search, 'returnUrl') || '';

const configs = {
  isLoggedIn: false,
  authenticated: !!window.sessionStorage.getItem('authenticated'),
  endPoint: `${REACT_APP_ENDPOINT}/${REACT_APP_ENDPOINT_VERSION}`,
  returnUrl,
};

export default configs;
