import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Footer from 'components/footer';
import LeftText from 'components/leftText';
import AccountsHeader from 'components/accountsHeader';

import { ConfigContext } from '../../App';

import './index.scss';

const Layout = props => {
  const {
    type,
    children,
    className,
  } = props;

  const { isNewsEnabled } = useContext(ConfigContext);

  return type === 'primary' ? (
    <ul className={classnames('layout-holder', className,)}>
      {isNewsEnabled && (
        <li className="l-left">
          <ul className="l-l-content-holder">
            <li>
              <LeftText/>
            </li>
            <li>
              <Footer/>
            </li>
          </ul>
        </li>
      )}
      <li className="l-right">
        <div className="l-r-content-holder">
          {children}
        </div>
      </li>
    </ul>
  ) : (
    <>
      <AccountsHeader/>
      <section>
        <div className="guide">
          {children}
        </div>
      </section>
      <Footer/>
    </>
  );
};

Layout.defaultProps = {
  type: 'primary',
};

Layout.propTypes = {
  type: PropTypes.oneOf(['primary', 'secondary']),
  children: PropTypes.any,
  className: PropTypes.string,
};

export default Layout;
