import React from 'react';

import './index.scss';

const Loader = ({ isSmall = false }) => (
  <div className="spinner-holder">
    <div className={isSmall ? 'spinner small' : 'spinner'}>
      <div className="dot dot1" />
      <div className="dot dot2" />
    </div>
  </div>
);

export default Loader;
